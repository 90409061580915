import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text, { TextType } from '../../../UiKit/Text'
import NumberSelectorInput from './NumberSelectorInput'
import { RootState } from '../../../../store'
import { ThunkDispatch } from 'redux-thunk'
import { getPrices } from '../../../../store/webshop/price/actions/getPrices'
import { connect } from 'react-redux'
import Button from '../../../UiKit/Button'
import RoutePaths from '../../../../Routes/RoutePaths'
import { useHistory } from 'react-router-dom'
import PaymentRequestContext from '../../../../Contexts/PaymentRequestContext'
import GiftType from '../../../../Models/GiftType'
import {
  createDecrementDigitalGiftAction,
  // createDecrementLetterGiftAction,
  createIncrementDigitalGiftAction,
  // createIncrementLetterGiftAction,
} from '../../../../Contexts/PaymentRequestContext/reducer'
import { formatPrice } from '../../../../utils'

interface Props extends MappedState, MappedDispatch {}

const NumberSelection: React.FunctionComponent<Props> = ({ getPrice, letterGiftPrice, digitalGiftPrice }) => {
  const { t } = useTranslation()
  const history = useHistory()
  useEffect(() => {
    getPrice()
  }, [getPrice])
  const {
    state: { data },
    dispatch,
  } = useContext(PaymentRequestContext)

  const onClickContinue = (): void => {
    if (data.gift_type === GiftType.DIGITAL) {
      history.push(RoutePaths.DIGITAL_GIFT)
    } else {
      history.push(RoutePaths.LETTER_GIFT)
    }
  }

  const onIncrementDigitalGift = (): void => dispatch(createIncrementDigitalGiftAction())
  const onDecrementDigitalGift = (): void => dispatch(createDecrementDigitalGiftAction())
  // const onIncrementLetterGift = (): void => dispatch(createIncrementLetterGiftAction())
  // const onDecrementLetterGift = (): void => dispatch(createDecrementLetterGiftAction())

  return (
    <>
      <Container>
        <div className="selection">
          <Text type={TextType.BIG_BOLD}>{t('digitalGift')}</Text>
          <NumberSelectorInput
            numberOfPeople={data.gift_type === GiftType.DIGITAL ? data.amount : 0}
            onIncrement={onIncrementDigitalGift}
            onDecrement={onDecrementDigitalGift}
          />
        </div>
        {/* <div className="selection">
          <Text type={TextType.BIG_BOLD}>{t('giftEnvelope')}</Text>
          <NumberSelectorInput
            numberOfPeople={data.gift_type === GiftType.LETTER ? data.amount : 0}
            onIncrement={onIncrementLetterGift}
            onDecrement={onDecrementLetterGift}
          />
        </div> */}
      </Container>
      {letterGiftPrice && digitalGiftPrice && (
        <div style={{ textAlign: 'right', paddingRight: 50, marginBottom: 20 }}>
          <Text>
            {t('total') + '. '}
            <Text type={TextType.BOLD}>
              {formatPrice(
                data.gift_type === GiftType.LETTER
                  ? (letterGiftPrice / 100) * data.amount
                  : (digitalGiftPrice / 100) * data.amount
              )}
            </Text>
          </Text>
        </div>
      )}
      <Button onClick={onClickContinue} id={'continue'}>
        {t('continue')}
      </Button>
    </>
  )
}

interface MappedState {
  letterGiftPrice?: number
  digitalGiftPrice?: number
}

interface MappedDispatch {
  getPrice: () => void
}

const mapStateToProps = (state: RootState): MappedState => ({
  letterGiftPrice: state.webshop.price.data && state.webshop.price.data.letter_gift,
  digitalGiftPrice: state.webshop.price.data && state.webshop.price.data.digital_gift,
})
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): MappedDispatch => ({
  getPrice: (): any => dispatch(getPrices()),
})
export default connect(mapStateToProps, mapDispatchToProps)(NumberSelection)

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 70px;
  .selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }
`
