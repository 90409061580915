import React, { useEffect, useContext, useCallback } from 'react'
import styled from 'styled-components'
import BuyHeader from '../../../BuyHeader'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import ReturnButton from '../../../UiKit/Button/ReturnButton'
import giftIcon from '../../../../assets/images/giftIcon.png'
import Text, { TextType } from '../../../UiKit/Text'
import ServiceDescription from '../../../ServiceDescription'
import mobileIcon from '../../../../assets/images/phoneIcon.png'
import addMemberIcon from '../../../../assets/images/addMember.png'
import NumberSelection from './NumberSelection'
import { createSetPaymentType } from '../../../../store/webshop/payment/form/actions/creators'
import ShoppingType from '../../../../Models/ShoppingType'
import { createSetShoppingTypeAction, createSetAmountAction } from '../../../../Contexts/PaymentRequestContext/reducer'
import PaymentRequestContext from '../../../../Contexts/PaymentRequestContext'

const NumberOfPeople: React.FunctionComponent<MappedDispatch> = ({ createSetPaymentType }) => {
  const { t } = useTranslation()
  const { dispatch } = useContext(PaymentRequestContext)

  const changeAmount = useCallback(
    (value: number) => {
      dispatch(createSetAmountAction(value))
    },
    [dispatch]
  )
  const changeShoppingType = useCallback(
    (value: ShoppingType) => {
      dispatch(createSetShoppingTypeAction(value))
    },
    [dispatch]
  )

  useEffect(() => {
    createSetPaymentType(ShoppingType.GIFT)
    changeShoppingType(ShoppingType.GIFT)
    changeAmount(1)
  }, [createSetPaymentType, changeShoppingType, changeAmount])

  return (
    <Container>
      <div className="container">
        <div className="return-button">
          <ReturnButton />
        </div>
        <BuyHeader imageSource={giftIcon} title={t('museumCardAsGift')} />
        <div className="description">
          <Text className="text-display font-size  bottom-distance">
            {t('shallWeDeliverGiftInDigitalFormOrEnvelope')}
          </Text>
          <Text className="font-size" type={TextType.BOLD}>
            {t('pleaseNote')}
          </Text>
          <Text className="font-size">{t('theDigitalGiftIsAlwaysOnTime')}</Text>
          <div dangerouslySetInnerHTML={{ __html: t('envelopeWillArriveInWeeks') }} />
          <br />
        </div>
        <NumberSelection />
        <ServiceDescription
          description={t('theDigitalGiftDescription')}
          header={t('theDigitalGift').toLocaleUpperCase()}
        >
          <img src={mobileIcon} alt="mobile" className="mobile-img" />
        </ServiceDescription>
        {/* <ServiceDescription
          description={t('theGiftEnvelopeExplanation')}
          header={t('theGiftEnvelope').toLocaleUpperCase()}
        >
          <img src={envelopeIcon} alt="envelope" className="envelope-img" />
        </ServiceDescription> */}
        <ServiceDescription
          description={t('anOrderOfMoreThanTenItemsByInvoiceExplanation')}
          description2={t('fillInTheOrderForm')}
          header={t('anOrderOfMoreThanTenItemsByInvoice').toLocaleUpperCase()}
        >
          <img src={addMemberIcon} alt="member" className="member-img" />
        </ServiceDescription>
        <div className="return-button">
          <ReturnButton />
        </div>
      </div>
    </Container>
  )
}
interface MappedDispatch {
  createSetPaymentType: (value: string) => void
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): MappedDispatch => ({
  createSetPaymentType: (value): any => dispatch(createSetPaymentType(value)),
})

export default connect(null, mapDispatchToProps)(NumberOfPeople)

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media only screen and (max-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
    margin: 0 !important;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }

  .container {
    text-align: center;
    flex-direction: column;
    max-width: 450px;
  }
  .description {
    margin: 20px 0;
  }
  .text-display {
    display: block;
  }
  .return-button {
    text-align: left;
    margin: 20px 0;
  }
  @media screen and (max-width: 767px) #return-button {
    max-width: 100%;
    margin-left: 0;
  }
  .bottom-distance {
    margin-bottom: 20px;
  }
  .mobile-img {
    max-height: 50px;
    max-width: 35px;
  }
  .envelope-img {
    max-height: 24px;
    max-width: 35px;
  }
  .member-img {
    max-height: 31px;
    max-width: 35px;
  }
  .font-size {
    font-size: 12pt;
  }
`
